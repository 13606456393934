/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit'
import { appApi } from './api'
import settingsReducer from './slices/settings'
import userReducer from './slices/user'

export const store = configureStore({
  reducer: {
    [appApi.reducerPath]: appApi.reducer,
    user: userReducer,
    settings: settingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      appApi.middleware,
    ),
})

export type RootState = ReturnType<typeof store.getState>
