import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Organization } from '../types'

export interface UserState {
  organizations: Organization[]
  selectedOrganization: Organization | null
}
const localSelectedOrganization = localStorage.getItem('selectedOrganization')

const initialState: UserState = {
  organizations: [],
  selectedOrganization: JSON.parse(localSelectedOrganization as string),
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload
      if (localSelectedOrganization) {
        const saved = JSON.parse(localSelectedOrganization)
        const foundOrg = state.organizations.find((org) => org.id === saved.id)
        if (foundOrg) state.selectedOrganization = foundOrg
      } else {
        const first = action.payload[0]
        state.selectedOrganization = first
        localStorage.setItem(
          'selectedOrganization',
          `${JSON.stringify({
            id: first.id,
            name: first.name,
          })}`,
        )
      }
    },
    selectOrganizationById: (state, action: PayloadAction<string>) => {
      const selectedOrg = state.organizations.find(
        (org) => org.id === action.payload,
      )
      if (selectedOrg) {
        state.selectedOrganization = selectedOrg
        localStorage.setItem(
          'selectedOrganization',
          `${JSON.stringify(selectedOrg)}`,
        )
      }
    },
  },
})

export const { setOrganizations, selectOrganizationById } = userSlice.actions
export default userSlice.reducer
