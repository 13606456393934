import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLazyGetMeQuery, useLazyInitUserQuery } from '../../store/api/user'
import { setOrganizations } from '../../store/slices/user'
import LoadingScreen from '../loading-screen'

type Props = {
  // used for redirecting out from routes like '/login' when the user is already logged in
  redirectIfAuthenticated?: boolean
}

export default function AuthProtectedContainer({
  redirectIfAuthenticated = false,
}: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [
    initUser,
    {
      isLoading: isLoadingInit,
      isError: initUserFailed,
      isSuccess: initSuccess,
      isUninitialized: isUninitializedInit,
    },
  ] = useLazyInitUserQuery()

  const [getMe, { data, isLoading, isSuccess }] = useLazyGetMeQuery()

  useEffect(() => {
    const init = async () => {
      await initUser()
      await getMe()
    }
    init()
  }, [])

  useEffect(() => {
    if (data?.organizations) {
      dispatch(setOrganizations(data.organizations))
    }
  }, [data])

  useEffect(() => {
    const onPop = () => {
      if (!data) navigate('/login', { replace: true })
    }
    window.addEventListener('popstate', onPop)
    return () => window.removeEventListener('popstate', onPop)
  }, [data])

  useEffect(() => {
    if (initUserFailed) {
      navigate('/login', { replace: true })
    }
  }, [initUserFailed])

  useEffect(() => {
    if (redirectIfAuthenticated && initSuccess && isSuccess) {
      navigate('/dashboard/main')
    }
  }, [initSuccess, isSuccess])

  return (
    <>
      <LoadingScreen show={isUninitializedInit || isLoadingInit || isLoading} />
      {isUninitializedInit || isLoadingInit || isLoading || <Outlet />}
    </>
  )
}
