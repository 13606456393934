import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface UserState {
  isDashboardEditing: boolean
}

const initialState: UserState = {
  isDashboardEditing: false,
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setIsDashboardEditing: (state, action: PayloadAction<boolean>) => {
      state.isDashboardEditing = action.payload
    },
  },
})

export const { setIsDashboardEditing } = settingsSlice.actions
export default settingsSlice.reducer
