const { VITE_USER_POOL_ID, VITE_USER_POOL_WEB_CLIENT_ID } = import.meta.env

const authConfig = {
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: VITE_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: VITE_USER_POOL_WEB_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    },
  },
}

export default authConfig
