/* eslint-disable import/no-cycle */
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { RootState } from '..'

const noOrgRoutes = [
  '/management/users',
  '/users/me',
  '/users/notification-settings',
  '/users/history-settings',
  '/organizations',
  '/notifications',
  '/reset-password',
] // urls including these won't have an org id prefix

function findKeyFromQueries(queries: any, searchStr: string) {
  return Object.keys(queries)
    .reverse() // reversing to get the newest query
    .find((k) => k.includes(searchStr))
}

// gets the user object given by the sdk, to get the id token for the Auth header
function getUserFromQueries(queries: any): any {
  // if we can't get the key from the login user query
  // we try to get it from the cookies
  const initKey = findKeyFromQueries(queries, 'init') || ''
  const token: any | unknown = queries[initKey]?.data
  return token as any
}

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
const rawBaseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { api } = getState() as RootState

    const token = getUserFromQueries(api.queries)

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError // | { status: number; data: User }
> = async (args, baseApi, extraOptions) => {
  const { user } = baseApi.getState() as RootState

  const selectedOrg = user.selectedOrganization

  const urlEnd = typeof args === 'string' ? args : args.url
  let adjustedUrl = ''

  // check if urlEnd is a no org route
  if (noOrgRoutes.some((route) => urlEnd.includes(route))) {
    adjustedUrl = `${urlEnd}`
  } else {
    if (!selectedOrg) {
      // need an org but don't have it
      return {
        error: {
          status: 400,
          statusText: 'Bad Request',
          data: 'Missing organization',
        },
      }
    }
    adjustedUrl = `/${selectedOrg.id}${urlEnd}`
  }

  const adjustedArgs =
    typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl }

  return rawBaseQuery(adjustedArgs, baseApi, extraOptions)
}

export const appApi = createApi({
  tagTypes: [
    'User',
    'Sources',
    'SourceData',
    'SourceMapping',
    'Organizations',
    'Organization',
    'Notifications',
    'Users',
    'Entities',
    'Chart',
    'ChartDetail',
    'ChartData',
  ],
  baseQuery: dynamicBaseQuery,
  endpoints: () => ({}),
})
