import { Transition } from '@headlessui/react'
import Logo from '../logo'

type Props = {
  show?: boolean
}

// show is true by default for the <Suspense /> fallback
export default function LoadingScreen({ show = true }: Props) {
  return (
    <Transition
      show={show}
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute inset-0 bg-white flex justify-center items-center z-50">
        <Logo />
      </div>
    </Transition>
  )
}
