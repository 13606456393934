import * as Sentry from '@sentry/react'
import { Amplify } from 'aws-amplify'
import { lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import authConfig from './auth/config'
import AuthProtectedContainer from './components/container/authProtected'
import LoadingScreen from './components/loading-screen'
import './i18n'
import MainLayout from './layouts/main'
import { store } from './store'
import { ConnectionsProvider } from './views/sources/context'

const MainDashboardView = lazy(() => import('./views/dashboard/main'))
const CreateDashboardView = lazy(() => import('./views/dashboard/create'))
const AddSourcesView = lazy(() => import('./views/sources/add-sources'))
const SendToEmailView = lazy(
  () => import('./views/sources/add-sources/sendToEmail'),
)
const UploadFilesView = lazy(
  () => import('./views/sources/add-sources/uploadFiles'),
)
const UploadToSFTPView = lazy(
  () => import('./views/sources/add-sources/uploadToSFTP'),
)
const UploadToS3View = lazy(
  () => import('./views/sources/add-sources/uploadToS3'),
)
const ForgotPasswordView = lazy(() => import('./views/forgot-password'))
const ForgotPasswordEmailView = lazy(
  () => import('./views/forgot-password/setPasswordEmail'),
)
const LoginView = lazy(() => import('./views/login'))
const RetrieveFromFTPView = lazy(
  () => import('./views/sources/add-sources/retrieveFromFTP'),
)
const RetrieveFromFinapiView = lazy(
  () => import('./views/sources/add-sources/retrieveFromFinapi'),
)
const DashboardView = lazy(() => import('./views/dashboard'))
const CreateAccountView = lazy(() => import('./views/create-account'))
const SetupMfaView = lazy(() => import('./views/setup-mfa'))
const SourcesView = lazy(() => import('./views/sources'))
const SourcesDatasetListView = lazy(() => import('./views/sources/datasetList'))
const SourcesDatasetDetailsView = lazy(
  () => import('./views/sources/sourceDetails'),
)
const NewFileUpload = lazy(() => import('./views/sources/newFileUpload'))
const UpdateSchedule = lazy(() => import('./views/sources/updateFtpSchedule'))
const EntitiesView = lazy(() => import('./views/entities'))
const AspectDetailsView = lazy(() => import('./views/entities/aspectDetails'))

const SettingsView = lazy(() => import('./views/settings'))
const ProfileSettingsView = lazy(() => import('./views/settings/tabs/profile'))
const DataRetentionsView = lazy(
  () => import('./views/settings/tabs/data-retention'),
)
const HistorySettingsView = lazy(() => import('./views/settings/tabs/history'))
const NotificationSettingsView = lazy(
  () => import('./views/settings/tabs/notification'),
)
const OrganizationManagementView = lazy(
  () => import('./views/settings/tabs/organization'),
)
const UserManagementView = lazy(
  () => import('./views/settings/tabs/user-management'),
)
const MfaSettingsView = lazy(() => import('./views/settings/tabs/mfa'))

Amplify.configure(authConfig)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  return (
    <Provider store={store}>
      <ConnectionsProvider>
        <BrowserRouter>
          <Suspense fallback={<LoadingScreen />}>
            <SentryRoutes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Navigate to="/login" />} />
                <Route path="create-account" element={<CreateAccountView />} />
                <Route
                  element={<AuthProtectedContainer redirectIfAuthenticated />}
                >
                  <Route path="login" element={<LoginView />} />
                  <Route
                    path="forgot-password"
                    element={<ForgotPasswordView />}
                  />
                </Route>
                <Route
                  path="forgot-password/:username"
                  element={<ForgotPasswordEmailView />}
                />
                <Route element={<AuthProtectedContainer />}>
                  <Route path="setup-mfa" element={<SetupMfaView />} />
                  <Route path="dashboard">
                    <Route index element={<DashboardView />} />
                    <Route path="main" element={<MainDashboardView />} />
                    <Route path="create" element={<CreateDashboardView />} />
                  </Route>
                  <Route path="sources">
                    <Route index element={<SourcesView />} />
                    <Route path="dataset-list">
                      <Route index element={<SourcesDatasetListView />} />
                      <Route
                        path=":id"
                        element={<SourcesDatasetDetailsView />}
                      />
                      <Route
                        path=":sourceId/update-schedule"
                        element={<UpdateSchedule />}
                      />
                    </Route>
                    <Route
                      path=":sourceId/upload"
                      element={<NewFileUpload />}
                    />

                    <Route path="entities">
                      <Route index element={<EntitiesView />} />
                      <Route path=":id" element={<AspectDetailsView />} />
                    </Route>
                    <Route path="add-sources">
                      <Route index element={<AddSourcesView />} />
                      <Route
                        path="upload-files"
                        element={<UploadFilesView />}
                      />
                      <Route
                        path="upload-to-sftp"
                        element={<UploadToSFTPView />}
                      />
                      <Route path="upload-to-s3" element={<UploadToS3View />} />
                      <Route
                        path="send-to-email"
                        element={<SendToEmailView />}
                      />
                      <Route
                        path="retrieve-from-ftp"
                        element={<RetrieveFromFTPView />}
                      />
                      <Route
                        path="retrieve-from-finapi"
                        element={<RetrieveFromFinapiView />}
                      />
                    </Route>
                  </Route>
                  <Route path="settings" element={<SettingsView />}>
                    <Route
                      path="profile-settings"
                      element={<ProfileSettingsView />}
                    />
                    <Route
                      path="history-settings"
                      element={<HistorySettingsView />}
                    />
                    <Route
                      path="data-retention-settings"
                      element={<DataRetentionsView />}
                    />
                    <Route
                      path="user-management"
                      element={<UserManagementView />}
                    />
                    <Route
                      path="organization-management"
                      element={<OrganizationManagementView />}
                    />
                    <Route
                      path="notification-settings"
                      element={<NotificationSettingsView />}
                    />
                    <Route path="mfa-settings" element={<MfaSettingsView />} />
                  </Route>
                </Route>
              </Route>
            </SentryRoutes>
          </Suspense>
        </BrowserRouter>
      </ConnectionsProvider>
    </Provider>
  )
}

export default App
