import {
  SignInOutput,
  fetchAuthSession,
  setUpTOTP,
  signIn,
  signOut,
} from '@aws-amplify/auth'
import { AuthTOTPSetupDetails } from '@aws-amplify/auth/dist/esm/types'
import { appApi } from '.'
import {
  CreateUser,
  GetAllUsers,
  GetUsernameByEmailArgs,
  GetUsernameByEmailResponse,
  UpdateSwitch,
  User,
  UserRole,
} from '../types'

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    initUser: builder.query<any, void>({
      providesTags: ['User'],
      queryFn: async () => {
        try {
          const response = fetchAuthSession()
          const res = await response
          const { tokens } = res
          return { data: tokens?.idToken?.toString() }
        } catch (error) {
          return {
            error: {
              status: 401,
              data: error,
            },
          }
        }
      },
    }),
    getMe: builder.query<User, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: ['User', 'Notifications'],
    }),
    loginUser: builder.query<SignInOutput, { email: string; password: string }>(
      {
        providesTags: ['User'],
        queryFn: async (formValues) => {
          // eslint-disable-next-line no-useless-catch
          try {
            const data = await signIn({
              username: formValues.email,
              password: formValues.password,
            })
            if (!data?.isSignedIn) {
              return {
                error: {
                  status: 401,
                  data,
                },
              }
            }
            return { data }
          } catch (error) {
            throw error
          }
        },
      },
    ),

    setupMFA: builder.query<AuthTOTPSetupDetails, undefined>({
      providesTags: ['User'],
      queryFn: async () => {
        try {
          const code = await setUpTOTP()

          return { data: code }
        } catch (error) {
          return {
            error: {
              status: 401,
              data: error,
            },
          }
        }
      },
    }),

    logoutUser: builder.mutation<null, void>({
      invalidatesTags: ['User'],
      queryFn: async () => {
        await signOut()
        localStorage.removeItem('selectedOrganization')
        return { data: null }
      },
    }),
    getAllUsers: builder.query<
      GetAllUsers,
      {
        pk?: string
        limit?: number
        organizationId?: string
        organizationName?: string
        status?: string
      }
    >({
      query: (params) => ({
        url: '/users',
        params,
        method: 'GET',
      }),
      providesTags: ['Users', 'User'],
    }),
    getManagementUsers: builder.query<any, undefined>({
      query: (params) => ({
        url: '/management/users',
        params,
        method: 'GET',
      }),
      transformResponse: (response: any) => ({
        users: response.map((item: any) => ({
          pk: item.email,
          ...item,
        })),
      }),

      providesTags: ['Users', 'User'],
    }),
    createNewUser: builder.mutation<any, CreateUser>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User', 'Notifications'],
    }),
    deleteUser: builder.mutation<any, { pk: string }>({
      query: (body) => ({
        url: '/users/delete-user',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    suspendUser: builder.mutation<any, { pk: string }>({
      query: (body) => ({
        url: '/users/disable-user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    unSuspendUser: builder.mutation<any, { pk: string }>({
      query: (body) => ({
        url: '/users/enable-user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<
      any,
      { pk: string; role?: UserRole; status?: string; organizations?: any[] }
    >({
      query: (body) => ({
        url: '/users/update-user',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users'],
    }),
    disableMfa: builder.mutation<void, { pk?: string }>({
      query: (body) => ({
        url: '/users/unset-mfa',
        body,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    updateNotification: builder.mutation<User, UpdateSwitch>({
      query: (body) => ({
        url: '/users/notification-settings',
        method: 'POST',
        body,
      }),
    }),
    updateHistory: builder.mutation<User, UpdateSwitch>({
      query: (body) => ({
        url: '/users/history-settings',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<
      GetUsernameByEmailResponse,
      GetUsernameByEmailArgs
    >({
      query: (body) => ({
        url: '/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLazyLoginUserQuery,
  useInitUserQuery,
  useLazyInitUserQuery,
  useLogoutUserMutation,
  useLazyGetMeQuery,
  useSetupMFAQuery,
  useLazySetupMFAQuery,
  endpoints: {
    getMe: { useQueryState: useGetMeState },
    initUser: { useQueryState: useInitUserState },
  },
  useGetAllUsersQuery,
  useGetManagementUsersQuery,
  useCreateNewUserMutation,
  useDeleteUserMutation,
  useSuspendUserMutation,
  useUnSuspendUserMutation,
  useUpdateUserMutation,
  useGetMeQuery,
  useDisableMfaMutation,
  useUpdateNotificationMutation,
  useUpdateHistoryMutation,
  useResetPasswordMutation,
} = userApi
