import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Column } from '../../components/table/types'

export type AspectConnections = {
  [entityId: string]: { [aspectId: string]: Column }
}

const ConnectionsContext = createContext<{
  connections: AspectConnections
  setConnections: Dispatch<SetStateAction<AspectConnections>>
}>({ connections: {}, setConnections: () => {} })

function ConnectionsProvider({ children }: PropsWithChildren) {
  const [connections, setConnections] = useState<any>({})

  const providerValue = useMemo(
    () => ({ connections, setConnections }),
    [connections, setConnections],
  )

  return (
    <ConnectionsContext.Provider value={providerValue}>
      {children}
    </ConnectionsContext.Provider>
  )
}

const useConnections = () => {
  const context = useContext(ConnectionsContext)
  if (!context) {
    throw Error('Use useConnections in ConnectionsProvider')
  }
  return context
}

export { ConnectionsProvider, useConnections }
